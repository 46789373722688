<script setup lang="ts">
import AppMarkdown from '../app/AppMarkdown.vue';

const spellStore = useSpellsStore();
const { spell } = storeToRefs(spellStore);
</script>
<template>
  <div class="flex flex-col items-start gap-2 mr-auto pt-l">
    <app-spell-icon :spell="spell" size="l" />

    <p class="main-title">
      {{ spell?.name }}
    </p>

    <p class="dimmed">By {{ spell?.creator?.fullName ?? 'Respell Team' }}</p>

    <AppMarkdown :value="spell?.description" max-height="200px" class="mt-2" />
  </div>
</template>
